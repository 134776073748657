import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import LogoApp from "./LogoApp";

export default function Footer() {
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 4, sm: 8 },
                py: { xs: 8, sm: 10 },
                textAlign: { sm: 'center', md: 'left' },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        minWidth: { xs: '100%', sm: '60%' },
                    }}
                >
                    <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
                        <LogoApp />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                        О продукте
                    </Typography>
                    <Link color="text.secondary" variant="body2" href="#features">
                        Зачем нужен ChatBoost
                    </Link>
                    <Link color="text.secondary" variant="body2" href="#pricing">
                        Цены
                    </Link>
                    <Link color="text.secondary" variant="body2" href={'/cancel-subscription'}>
                        Отмена подписки
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                        Документы
                    </Typography>
                    <Link color="text.secondary" variant="body2" href={'/privacy'}>
                        Политика конфиденциальности
                    </Link>
                    <Link color="text.secondary" variant="body2" href={'/offer'}>
                        Публичная оферта
                    </Link>
                    <Link color="text.secondary" variant="body2" href={'/recurrent-offer'}>
                        Оферта рекуррентных платежей
                    </Link>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: { xs: 4, sm: 8 },
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <div>
                    <Link color="text.secondary" variant="body2" href={'/privacy'}>
                        Политика конфиденциальности
                    </Link>
                    <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                        ИП Котельников Юрий Тимофеевич ИНН: 667354456790
                    </Typography>
                </div>
            </Box>
        </Container>
    );
}