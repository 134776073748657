import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Button, Box, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TelegramIcon from '@mui/icons-material/Telegram';
import MainMenu from "../../components/Main/MainMenu";
import {useTranslation} from "react-i18next";

const SupportPage = () => {
    const { t } = useTranslation();

    return (
        <MainMenu>
            <Container maxWidth="md" sx={{ marginTop: 4 }}>
                <Typography variant="h4" gutterBottom>
                    {t('support_title')}
                </Typography>

                <Typography variant="h5" gutterBottom>
                    {t('faq')}
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{t('faq_title_1')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('faq_description_1')}
                        </Typography>
                        <img src="/demo.png" alt="Иконка для подсказок" style={{width: '500px', margin: '10px auto'}}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{t('faq_title_2')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t('faq_description_2')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* Contact Section */}
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>
                        {t('contact')}
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary={<>
                                Email: <a href="mailto:support@chatboost.space">support@chatboost.space</a>
                            </>} />
                        </ListItem>
                    </List>
                </Box>

                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>
                        {t('chat_support')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('chat_support_description')}
                    </Typography>
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} href={'https://t.me/ChatBoos_bot'} target="_blank">
                        {t('open_chat')}
                    </Button>
                </Box>

                {/* Telegram Section */}
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>
                        {t('telegram_chanel')}
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<TelegramIcon />}
                        href="https://t.me/B24chatBoost"
                        target="_blank"
                        sx={{ mt: 2 }}
                    >
                        {t('subscribe_tg')}
                    </Button>
                </Box>
            </Container>
        </MainMenu>
    );
};

export default SupportPage;
