import React, {FC, useEffect, useState} from 'react';
import {Box, CircularProgress, List, ListItem, ListItemButton, ListItemText, Tooltip, Typography} from "@mui/material";
import AddList from "./buttons/AddList";
import Header from "./Header";
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";
import {useListStorage} from "../../storage/ListStorage";
import EditListButton from "./buttons/EditListButton";
import Joyride, {Placement} from "react-joyride";
import Button from "@mui/material/Button";
import ym from "react-yandex-metrika";
import {Trans, useTranslation} from "react-i18next";


const ListPage: FC = () => {
    const navigate = useNavigate();
    const {lists}=useListStorage()
    const { t } = useTranslation();

    const [run, setRun] = useState(false);
    const steps = [
        {
            target: 'body',
            content: (
                <div style={{textAlign: 'center'}}>
                    <p>{t('training_description_step_1')}</p>
                    <img src="/demo.png" alt="Иконка для подсказок" style={{width: '500px', margin: '10px auto'}}/>
                </div>
            ),
            placement: 'center' as Placement,
            title: t('welcome')
        },
        {
            target: 'body',
            content: (
                <div dangerouslySetInnerHTML={{__html: t('training_description_step_2')}}/>
            ),
            placement: 'center' as Placement,
            title: t('training_title_step_2')
        },
        {
            target: 'body',
            content: (
                <div dangerouslySetInnerHTML={{__html: t('training_description_step_3')}}/>
            ),
            placement: 'center' as Placement,
            title: t('training_title_step_3')
        },
        {
            target: '.step-1',
            content: (
                <div dangerouslySetInnerHTML={{__html: t('training_description_step_4')}}/>
            ),
            title: t('training_title_step_4')
        },
        {
            target: '.step-2',
            content: (
                <div dangerouslySetInnerHTML={{__html: t('training_description_step_5')}}/>
            ),
            title: t('training_title_step_5')
        },
        {
            target: '.step-3',
            content: t('training_description_step_6'),
        },
    ];
    const startTutorial = () => {
        ym('reachGoal','start_tutorial')
        setRun(true);
    };
    return (
        <>
            <Joyride
                steps={steps}
                run={run}
                continuous
                showSkipButton
                locale={{
                    next: t('next'),
                    skip: t('skip'),
                    back: t('back'),
                    close: t('close'),
                    last: t('last')
                }}
                styles={{
                    options: {
                        zIndex: 10000,
                        backgroundColor: '#fff',
                        overlayColor: 'rgba(0, 0, 0, 0.5)',
                        width: 550,
                    },
                    buttonNext: {
                        backgroundColor: '#1976d2',
                        color: '#fff',
                    },
                }}
                callback={(data) => {
                    const { status } = data;
                    if (status === 'finished' || status === 'skipped') {
                        setRun(false);
                        ym('reachGoal','training')
                    }
                }}
            />
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: {xs: 8, md: 0},
                }}
            >
                <Header>
                    <Button onClick={startTutorial}>{t('start_training')}</Button>
                    <AddList/>
                </Header>

                <List className="step-2"
                >
                    {
                        lists.map((item, index) => (
                            <ListItem key={`list-${item.id}-${index}`} secondaryAction={
                                <Stack direction={'row'} gap={2} alignItems="center">
                                    <div className="step-3">
                                        <EditListButton name={item.name} listId={item.id}
                                                        teamIds={item.teams.map((t) => t.id)}/>
                                    </div>
                                    <Tooltip title={t('count_suggestions_in_list')}>
                                        <Typography>{item.suggestionsCount}</Typography>
                                    </Tooltip>
                                </Stack>
                            }>
                                <ListItemButton
                                    onClick={() => navigate(`/list/${item.id}`)}
                                >
                                    <ListItemText primary={item.name} secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                sx={{color: 'text.primary', display: 'inline'}}
                                            >
                                                {t('for_teams')}
                                            </Typography>
                                            {` ${
                                                item.teams.map((team) => team.name).join(', ')
                                            }`}
                                        </React.Fragment>
                                    }/>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }

                </List>
            </Stack>


        </>
    );
};

export default ListPage;