import React, {useEffect} from 'react';
import './FileHeader.css'
import {useParams, useSearchParams} from "react-router-dom";
import ListPage from "../../components/Main/ListPage";
import MainMenu from "../../components/Main/MainMenu";
import {useAppStorage} from "../../storage/AppStorage";
import {useTranslation} from "react-i18next";


const MainPage = () => {
    const {portalId} = useParams();
    const [searchParams] = useSearchParams();
    const {setToken,setPortalId}=useAppStorage()
    const { t } = useTranslation();

    useEffect(() => {
        if (searchParams.has('token')) {
            localStorage.setItem('authToken', searchParams.get('token')!)
            localStorage.setItem('portalId', portalId??'')
            setToken(searchParams.get('token')??'')
            setPortalId(portalId??'')
        } else {
            alert(t('error_token_not_received'))
        }
    }, []);



    return (
        <>
            <MainMenu>
                <ListPage/>
            </MainMenu>
        </>

    );
};

export default MainPage;