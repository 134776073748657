import React, {FC} from 'react';
import {
    Checkbox, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemButton, ListItemIcon, ListItemText,
    Tooltip,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Edit} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import { SuggestionResponse } from '../../../types/api/SuggestionResponse';
import $api from "../../../api/api";
import {AxiosError} from "axios";
import {useTranslation} from "react-i18next";


type Inputs = {
    name:string
    message:string

}

interface EditSuggestionAttrs{
    suggestion:SuggestionResponse
}

const EditSuggestionButton:FC<EditSuggestionAttrs> = ({suggestion}) => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control
    } = useForm<Inputs>({
        defaultValues:{
            name:suggestion.name,
            message:suggestion.message,
        }
    })

    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        try{
            const res=await $api.put(
                `/portal/team/${suggestion.id}`,
                data
            )
            handleClose()
        } catch (e){
            if(e instanceof AxiosError){
                alert(`${e.response?.data.message}`)
            }else{
                alert(e)
            }
        }
    }

    return (
        <>
            <Tooltip title={t('tooltip_edit_suggestion')}>
                <IconButton onClick={handleClickOpen}>
                    <Edit/>
                </IconButton>
            </Tooltip>


            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                }}
            >

                <DialogTitle>{t('edit_suggestion_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('edit_suggestion_description')} {suggestion.name}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label={t('label_name_suggestion')}
                        fullWidth
                        variant="outlined"
                        {...register('name',{required:{value:true,message:t('error_fill_in_the_field')}})}
                    />

                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label={t('label_test_suggestion')}
                        fullWidth
                        variant="outlined"
                        maxRows={10}
                        multiline
                        rows={5}
                        {...register('message',{required:{value:true,message:t('error_fill_in_the_field')}})}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button type="submit">{t('save')}</Button>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default EditSuggestionButton;