import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import {OrderServices} from "../../api/services/OrderServices";
import HandleError from "../../utils/HandleError";

interface UnsubscribeFormInputs {
    domain: string;
    email: string;
}

const UnsubscribeForm: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<UnsubscribeFormInputs>();

    const onSubmit: SubmitHandler<UnsubscribeFormInputs> =async (data) => {
        try{
            const res=await OrderServices.cancelSubscription({email:data.email,domain:data.domain})
            alert('Мы приняли ваш запрос. Вся информация отправлена на указанную почту')
        }catch (e){
            alert(HandleError(e))
        }
    };

    return (
        <Container maxWidth="sm" sx={{ marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Отписка от подписки на приложение ChatBoost
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={2}>
                    <TextField
                        label="Домен Битрикс24"
                        fullWidth
                        {...register('domain', { required: 'Домен обязателен к заполнению' })}
                        error={!!errors.domain}
                        helperText={errors.domain ? errors.domain.message : ''}
                    />
                </Box>

                <Box mb={2}>
                    <TextField
                        label="Email"
                        fullWidth
                        {...register('email', {
                            required: 'Email обязателен к заполнению',
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Введите корректный Email',
                            },
                        })}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ''}
                    />
                </Box>

                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Отписаться
                </Button>
            </form>

            <Box mt={4}>
                <Typography variant="body2">
                    <Link to={'/offer'}>В соответствии с разделом 3,</Link> отмена подписки произойдет в течение 3 рабочих дней. На указанный Email будет отправлено письмо с информацией. Вы сможете продолжать использовать приложение до конца оплаченного периода.
                </Typography>
            </Box>
        </Container>
    );
};

export default UnsubscribeForm;
