import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import BuyOnSiteButton from "../Main/buttons/BuyOnSiteButton";
import Stack from "@mui/material/Stack";

const tiers = [
    {
        title: 'Месяц',
        price: '499',
        description: [
            'Ответы с файлами',
            'Работа с командами',
            'Статистика',
        ],
        buttonText: 'В магазин приложений',
        buttonVariant: 'contained',
        buttonColor: 'primary',
    },
];

export default function Pricing() {
    return (
        <Container
            id="pricing"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        ><Typography
            component="h2"
            variant="h4"
            gutterBottom
            sx={{ color: 'text.primary' }}
        >
            Доступно в подписке Битрикс24 Маркет
        </Typography>


        </Container>
    );
}