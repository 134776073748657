import React from 'react';
import Button from "@mui/material/Button";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemButton, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import $api from "../../../api/api";
import {useNavigate} from "react-router-dom";
import {useListStorage} from "../../../storage/ListStorage";
import {useTeamsStorage} from "../../../storage/TeamStore";
import {useTranslation} from "react-i18next";

type Inputs = {
    name: string
    teamIds:string[]
}

const AddList = () => {
    const [open, setOpen] = React.useState(false);
    const {addList}=useListStorage()
    const {teams}=useTeamsStorage()
    const { t, } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
        reset
    } = useForm<Inputs>({
        defaultValues:{
            teamIds:[]
        }
    })
    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        try{
            const res=await $api.post(
                '/suggestions/lists',
                data
            )
            addList(res.data)
            handleClose()
            reset()
        } catch (e){
            alert(e)

        }
    }


    return (
        <>
            <Button variant="outlined" className="step-1" onClick={handleClickOpen}>
                {t('create_list')}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                }}
            >
                <DialogTitle>{t('new_list')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('new_list_description')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label={t('te_name_list')}
                        fullWidth
                        variant="outlined"
                        {...register('name',{required:{value:true,message:t('error_fill_in_the_field')}})}
                    />
                    <Typography>{t('new_list_select_teams')}</Typography>
                    <Controller
                        render={({field:{value,onChange}})=>(
                            <List disablePadding>
                                {
                                    teams.map((team,index)=>{
                                        const labelId = `checkbox-list-label-${team.id}`;

                                        const handleToggle = (id: string) => () => {
                                            const currentIndex = value.indexOf(id);
                                            const newChecked = [...value];

                                            if (currentIndex === -1) {
                                                newChecked.push(id);
                                            } else {
                                                newChecked.splice(currentIndex, 1);
                                            }

                                            onChange(newChecked);
                                        };

                                        return (
                                            <ListItem
                                                key={team.id}
                                                disablePadding
                                            >
                                                <ListItemButton role={undefined} onClick={handleToggle(team.id)} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={value.indexOf(team.id) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={team.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                            )}
                        name={'teamIds'}
                        control={control}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button type="submit">{t('save')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddList;