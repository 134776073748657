import React, {useEffect} from 'react';
import MainMenu from "../../components/Main/MainMenu";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {
    Alert,
    AppBar, Checkbox,
    Dialog,
    Divider,
    List,
    ListItem, ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper, Slide,
    Typography
} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Dropzone from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import {Delete, InsertDriveFile} from "@mui/icons-material";
import {formatFileSize} from "../../utils/utils";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../components/Main/Header";
import {ButtonWithState, useButtonController} from "../../components/ButtonWithState";
import {FileResponse} from "../../types/api/FileResponse";
import {FileService} from "../../api/services/FileService";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import {useTranslation} from "react-i18next";
import {AppServices} from "../../api/services/AppServices";
import handleError from "../../utils/HandleError";
import {useSuggestionStorage} from "../../storage/SuggestionStore";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type Inputs = {
    name: string
    description: string
    message: string
    listId: string
    files: File[]
    existsFiles:FileResponse[]

}
const NewSuggestionPage = () => {
    const {listId}=useParams()
    const [files, setFiles] = React.useState<FileResponse[]>([])
    const buttonController = useButtonController();
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const {addSuggestion}=useSuggestionStorage()
    const route=useNavigate()

    useEffect(()=>{
        fetchSuggestions().then()
    },[])

    async function fetchSuggestions(){
        try{
            const res=await FileService.fetchFiles()
            setFiles(res)
        }catch (e){
        }
    }

    const handleClose = () => {
        setOpen(false);
    };


    const {
        register,
        handleSubmit,
        watch,
        formState: {errors,},
        control,
        reset,
        setError,
        clearErrors
    } = useForm<Inputs>({
        defaultValues: {
            listId: listId,
            files:[],
            existsFiles:[]
        }
    })

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        clearErrors();
        try {
            buttonController.startLoading();
            const res = await AppServices.createSuggestion(data)
            addSuggestion(res)
            await buttonController.showSuccess();
            reset()
            route(`/list/${listId}`)
        } catch (e) {
            await buttonController.showError();
            setError('files',{message:handleError(e)})
        }
    }

    return (
        <MainMenu>
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: {xs: 8, md: 0},
                }}
            >
                <Header>
                </Header>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label={t('name')}
                        fullWidth
                        variant="outlined"
                        {...register('name',{required:{value:true,message:t('error_fill_in_the_field')}})}
                    />
                    <TextField
                        required
                        margin="dense"
                        label={t('label_test_suggestion')}
                        fullWidth
                        variant="outlined"
                        maxRows={10}
                        multiline
                        rows={5}
                        {...register('message',{required:{value:true,message:t('error_fill_in_the_field')}})}
                    />
                    <Stack
                        direction="row"
                        sx={{
                            width: '100%',
                            alignItems: { xs: 'flex-start', md: 'center' },
                            justifyContent: 'space-between',
                            maxWidth: { sm: '100%', md: '1700px' },
                            pt: 1.5,
                        }}
                        spacing={2}
                    >
                        <Typography>{t('add_files_to_suggestion')}</Typography>
                        <Button onClick={()=>setOpen(true)}>{t('btn_select_file_from_exist')}</Button>
                    </Stack>
                    <Controller
                        render={({ field:{value,onChange,onBlur,name}}) => (
                            <>
                                <Dropzone
                                    onDrop={acceptedFiles=>onChange([...acceptedFiles,...value])}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <Paper
                                            elevation={0} variant={'outlined'} {...getRootProps()}
                                            sx={{
                                                height: 100,
                                                textAlign: 'center',
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor:"#f4f4f4",
                                                cursor:'pointer',
                                                padding: '10px',
                                                marginTop: '10px',
                                                alignContent: 'center',
                                            }}>
                                            <input {...getInputProps()} name={name} onBlur={onBlur}/>
                                            <p>{t('drag_and_drop_message')}</p>
                                        </Paper>
                                    )}
                                </Dropzone>
                                {
                                    (value)&&<List>
                                        {
                                            value.map((item, index) => (
                                                <ListItem
                                                    key={`file-${index}`}
                                                    secondaryAction={
                                                        <IconButton
                                                            onClick={()=>onChange(value.filter(file=>file!==item))}
                                                        >
                                                            <Delete/>
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <InsertDriveFile/>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={item.name}
                                                        secondary={formatFileSize(item.size)}
                                                    />
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                }
                            </>
                        )}
                        name={'files'}
                        control={control}/>
                    {
                        (errors.files!=undefined)&&<Alert variant="outlined" severity="error">{errors.files?.message}</Alert>
                    }
                    <Controller
                        render={({ field:{value,onChange,}, fieldState, formState, })=>(
                            <>
                                <Divider/>
                                <List>
                                    {
                                        value.map((item, index) => (
                                            <ListItem key={`file-${index}`} secondaryAction={
                                                <IconButton
                                                    onClick={()=>onChange(value.filter(file=>file!==item))}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            }>
                                                <ListItemText
                                                    primary={item.name}
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </>
                        )}
                        name={'existsFiles'}
                        control={control}/>


                    <ButtonWithState fullWidth={true} variant={"contained"} type="submit" controller={buttonController}>{t('btn_create_suggestion')}</ButtonWithState>
                </form>



            </Stack>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t('select_exist_file')}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            {t('save')}
                        </Button>
                    </Toolbar>
                </AppBar>

                {
                    files.length==0&&t('files_not_found')
                }
                {
                    files.length>0&&
                    <Controller
                        render={({ field:{value,onChange,onBlur}, fieldState, formState, })=>(
                            <List>
                                {
                                    files.map((item,index) =>{
                                        const labelId = `checkbox-list-label-${index}`;

                                        const handleToggle = (v: FileResponse) => () => {
                                            const currentIndex = value.findIndex((item: FileResponse) => item.id === v.id);
                                            const newChecked = [...value];

                                            if (currentIndex === -1) {
                                                newChecked.push(v);
                                            } else {
                                                newChecked.splice(currentIndex, 1);
                                            }
                                            onChange(newChecked);
                                            console.log(value)
                                        };
                                        return (
                                            <ListItem key={item.id}>
                                                <ListItemButton role={undefined} onClick={handleToggle(item)} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={value.some((i: FileResponse) => i.id === item.id)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={`${item.name}`} />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        )}
                        name={'existsFiles'}
                        control={control}
                        />
                }
            </Dialog>
        </MainMenu>
    );
};

export default NewSuggestionPage;