import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import {useLocation} from "react-router-dom";
import {useAppStorage} from "../../storage/AppStorage";
import {useTeamsStorage} from "../../storage/TeamStore";
import {useListStorage} from "../../storage/ListStorage";
import {useTranslation} from "react-i18next";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
    margin: theme.spacing(1, 0),
    [`& .${breadcrumbsClasses.separator}`]: {
        color: theme.palette.action.disabled,
        margin: 1,
    },
    [`& .${breadcrumbsClasses.ol}`]: {
        alignItems: 'center',
    },
}));

type BreadcrumbItem = {
    uuid: string;
    name: string;
};


export default function NavbarBreadcrumbs() {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const {teams}=useTeamsStorage()
    const {lists}=useListStorage()
    const { t } = useTranslation();


    const dataMap: { [key: string]: BreadcrumbItem[] } = {
        list: lists.map((l)=>({uuid:l.id,name:l.name} as BreadcrumbItem)),
        teams:teams.map((l)=>({uuid:l.id,name:l.name} as BreadcrumbItem)),
    };

    const getBreadcrumbName = (segment: string, parentSegment?: string): string => {
        if (!parentSegment || !dataMap[parentSegment]) {
            return segment;
        }
        const match = dataMap[parentSegment].find(item => item.uuid === segment);
        return match ? match.name : segment;
    };

    return (
        <StyledBreadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextRoundedIcon fontSize="small" />}
        >
            <Typography variant="body1">ChatBoost</Typography>

            {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                const isLast = index === pathnames.length - 1;

                const breadcrumbText =t(value) ||
                    (index > 0 ? getBreadcrumbName(value, pathnames[0]) : value.charAt(0).toUpperCase() + value.slice(1));

                return isLast ? (
                    <Typography
                        variant="body1"
                        sx={{ color: 'text.primary', fontWeight: 600 }}
                        key={to}
                    >
                        {breadcrumbText}
                    </Typography>
                ) : (
                    <Typography key={`StyledBreadcrumbs-${index}`} variant="body1" sx={{ color: 'text.primary',}}>
                        {breadcrumbText}
                    </Typography>
                );
            })}



        </StyledBreadcrumbs>
    );
}