import React, {useEffect, useState} from 'react';
import MainMenu from "../../components/Main/MainMenu";
import Stack from "@mui/material/Stack";
import Header from "../../components/Main/Header";
import ReuseComponentBuilder from "../../components/ReuseComponent";
import {FileResponse} from "../../types/api/FileResponse";
import {Box, List, ListItem, ListItemText, Typography} from "@mui/material";
import {FileService} from "../../api/services/FileService";

const FilesListPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [files, setFiles] = useState<FileResponse[]>([]);
    const [error, setError] = useState<Error | null | unknown>(null);

    useEffect(()=>{
        fetchSuggestions().then()
    },[])

    async function fetchSuggestions(){
        setLoading(true)
        try{
            const res=await FileService.fetchFiles()
            setFiles(res)
        }catch (e){
            setError(e)
        }finally {
            setLoading(false)
        }
    }

    return (
        <MainMenu>
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: { xs: 8, md: 0 },
                }}
            >
                <Header>
                </Header>
                <ReuseComponentBuilder<FileResponse[]>
                    loading={loading}
                    error={error}
                    data={files}

                >
                    {(data) => (
                        <>
                            {
                                data.length==0 && <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="80vh"
                                    flexDirection="column"
                                >
                                    <Typography variant="h6" gutterBottom>
                                        Список пуст.
                                    </Typography>
                                </Box>
                            }
                            {
                                data.length>0&&<List>
                                    {
                                        data.map((item, index) => (
                                            <ListItem key={`file-${item.id}-${index}`}>
                                                <ListItemText
                                                    primary={`${item.name}`}
                                                    secondary={`Тип: ${item.name.split('.')[item.name.split('.').length-1]}`}
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            }
                        </>
                    )}
                </ReuseComponentBuilder>
            </Stack>
        </MainMenu>
    );
};

export default FilesListPage;