import React, {FC, useState} from 'react';
import Button from "@mui/material/Button";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List, ListItem, ListItemIcon,
    ListItemText,
    Paper, Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Dropzone from "react-dropzone";
import {CloudUpload, Delete, InsertDriveFile} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {AppServices} from "../../../api/services/AppServices";
import {ButtonWithState, useButtonController} from "../../ButtonWithState";
import {useSuggestionStorage} from "../../../storage/SuggestionStore";
import handleError from "../../../utils/HandleError";
import {formatFileSize} from "../../../utils/utils";
import {FileResponse} from "../../../types/api/FileResponse";

interface AddAddSuggestionProps {
    listId: string
}

type Inputs = {
    name: string
    description: string
    message: string
    listId: string
    files: File[]
    existsFiles:FileResponse[]
}


const AddSuggestion: FC<AddAddSuggestionProps> = ({listId}) => {
    const [open, setOpen] = React.useState(false);
    const buttonController = useButtonController();
    const {addSuggestion}=useSuggestionStorage()
    const [error, setError] = React.useState<string|undefined>()


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
        control,
        reset
    } = useForm<Inputs>({
        defaultValues: {
            listId: listId,
            files:[]
        }
    })
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setError(undefined);
        try {
            buttonController.startLoading();
            const res = await AppServices.createSuggestion(data)
            addSuggestion(res)
            await buttonController.showSuccess();
            handleClose()
            reset()
        } catch (e) {
            await buttonController.showError();
            setError(handleError(e))
        }
    }
    return (
        <>
            <Button variant="outlined" onClick={handleClickOpen}>
                Создать Подсказку
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                }}
            >
                <DialogTitle>Новая подсказка</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Для создания подсказки заполните поля и добавьте файлы, если это необходимо
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label="Название"
                        fullWidth
                        variant="outlined"
                        {...register('name',{required:{value:true,message:'Заполните поле'}})}
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label="Текст подсказки"
                        fullWidth
                        variant="outlined"
                        maxRows={10}
                        multiline
                        rows={5}
                        {...register('message',{required:{value:true,message:'Заполните поле'}})}
                    />
                    <Typography>Добавте файлы для подсказки:</Typography>

                    <Controller
                        render={({ field:{value,onChange,onBlur,name}}) => (
                            <>
                                <Dropzone
                                    onDrop={acceptedFiles=>onChange([...acceptedFiles,...value])}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <Paper
                                            elevation={0} variant={'outlined'} {...getRootProps()}
                                            sx={{
                                                height: 100,
                                                textAlign: 'center',
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor:"#eee",
                                                cursor:'pointer',
                                                padding: '10px',
                                                marginTop: '10px',
                                                alignContent: 'center',
                                            }}>
                                            <input {...getInputProps()} name={name} onBlur={onBlur}/>
                                            <p>Перетащите файлы сюда или нажмите для добавления</p>
                                        </Paper>
                                    )}
                                </Dropzone>
                                {
                                    (value)&&<List>
                                        {
                                            value.map((item, index) => (
                                                <ListItem
                                                    key={`file-${index}`}
                                                    secondaryAction={
                                                        <IconButton
                                                            onClick={()=>onChange(value.filter(file=>file!==item))}
                                                        >
                                                            <Delete/>
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <InsertDriveFile/>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={item.name}
                                                        secondary={formatFileSize(item.size)}
                                                    />
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                }
                            </>
                        )}
                        name={'files'}
                        control={control}/>
                    {
                        (error!=undefined)&&<Alert variant="outlined" severity="error">{error}</Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <ButtonWithState type="submit" controller={buttonController}>Создать</ButtonWithState>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddSuggestion;