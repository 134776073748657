import {SuggestionListResponse} from "../../types/api/SuggestionListResponse";
import $api from "../api";
import {FileResponse} from "../../types/api/FileResponse";

export class FileService{
    static async fetchFiles():Promise<FileResponse[]>{
        try {
            const res=await $api.get<FileResponse[]>(
                '/files',
                {
                    params:{
                        time:new Date().getTime(),
                    }
                }
            );
            return res.data;
        }catch (e) {
            throw e
        }
    }
}