import React, {FC} from 'react';
import IconButton from "@mui/material/IconButton";
import {Edit} from "@mui/icons-material";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel, Tooltip
} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import $api from "../../../api/api";
import {AxiosError} from "axios";
import {useTeamsStorage} from "../../../storage/TeamStore";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";

type Inputs = {
    name: string
    defaultTeam:boolean
    teamId:string
    description:string
}

interface EditTeamProps {
    teamId:string;
    name:string;
    defaultTeam:boolean;
}

const EditTeam:FC<EditTeamProps> = ({teamId,name,defaultTeam}) => {
    const [open, setOpen] = React.useState(false);
    const {unsetTeam}=useTeamsStorage()
    const { t } = useTranslation();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
        reset
    } = useForm<Inputs>({
        defaultValues:{
            defaultTeam:defaultTeam,
            teamId:teamId,
            name:name,
            description:''
        }
    })

    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        try{
            const res=await $api.put(
                `/portal/team/${data.teamId}`,
                data
            )
            unsetTeam(teamId,res.data )
            handleClose()
            reset()
        } catch (e){
            if(e instanceof AxiosError){
                alert(`${e.response?.data.message}`)
            }else{
                alert(e)
            }
        }
    }

    return (
        <>
            <Tooltip title={t('tooltip_edit_team')}>
                <IconButton onClick={handleClickOpen}>
                    <Edit/>
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                }}
            >
                <DialogTitle>{t('edit_team_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('edit_title_description')} {name}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label={t('label_team_name')}
                        fullWidth
                        variant="outlined"
                        {...register('name',{required:{value:true,message:t('error_fill_in_the_field')}})}
                    />
                    <Controller
                        render={({field:{onBlur,value,onChange}})=>(
                            <>
                                <FormControlLabel
                                    control={<Checkbox checked={value} onChange={onChange} onBlur={onBlur}/>}
                                    label={t('default_team')}
                                />
                            </>
                        )}
                        name={'defaultTeam'}
                        control={control}/>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button type="submit">{t('save')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditTeam;