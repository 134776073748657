import React from 'react';
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import $api from "../../../api/api";
import {AxiosError} from "axios";
import {useTeamsStorage} from "../../../storage/TeamStore";
import {useTranslation} from "react-i18next";

type Inputs = {
    name: string
    description:string
    defaultTeam:boolean
}

const AddTeam = () => {

    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const {addTeam}=useTeamsStorage()
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
        reset
    } = useForm<Inputs>({
        defaultValues:{
            description:'',
            defaultTeam:false
        }
    })

    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        try{
            const res=await $api.post(
                '/portal/team',
                data
            )
            handleClose()
            addTeam(res.data)
            reset()
        } catch (e){
            if(e instanceof AxiosError){
                alert(`${e.response?.data.message}`)
            }else{
                alert(e)
            }
        }
    }

    return (
        <>
            <Button variant="outlined" onClick={handleClickOpen}>
                {t('btn_create_team')}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                }}
            >
                <DialogTitle>{t('new_team_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('new_team_description')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label={t('label_team_name')}
                        fullWidth
                        variant="standard"
                        {...register('name',{required:{value:true,message:t('error_fill_in_the_field')}})}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button type="submit">{t('create')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddTeam;