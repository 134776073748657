import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import Link from "@mui/material/Link";

const PublicRecurrentOfferPage = () => {
    return (
        <Container maxWidth="md" sx={{ padding: '20px' }}>
            <Typography variant="h4" align="center" gutterBottom>
                ПУБЛИЧНАЯ ОФЕРТА О ПРЕДОСТАВЛЕНИИ УСЛУГИ «РЕКУРРЕНТНЫЙ ПЛАТЕЖ»
            </Typography>
            <Box mt={2}>
                <Typography variant="h6">Россия, г. Екатеринбург</Typography>
                <Typography variant="subtitle1">Редакция от 21 сентября 2024 года</Typography>
            </Box>

            <Divider sx={{ marginY: 2 }} />


            <Typography paragraph>
                Настоящей публичной офертой (далее по тексту именуемой «Оферта») Индивидуальный предприниматель
                Котельников Юрий Тимофеевич, именуемый в дальнейшем «Исполнитель», с одной стороны, предлагает
                воспользоваться услугой «Рекуррентный платеж» на условиях настоящей Оферты любому отозвавшемуся
                физическому или юридическому лицу (далее по тексту именуемому «Заказчик»), которое примет настоящее
                предложение на указанных ниже условиях. Оферта является приложением к Публичной оферте: предложению о
                заключении договора возмездного оказания услуг по подписке, опубликованной на сайте{' '}
                <Link href="https://chatboost.space" target="_blank" rel="noopener noreferrer">
                    https://chatboost.space
                </Link>{' '}
                (далее по тексту именуемый «Сайт Исполнителя»).
            </Typography>

            <Typography paragraph>
                Пользователь (Заказчик) безусловно и безоговорочно соглашается с настоящей Офертой путем совершения
                конклюдентных действий по подключению сервиса «Рекуррентный платеж» в момент принятия Заказчиком
                настоящего Соглашения и выполнения действий по активации сервиса «Рекуррентный платеж».
            </Typography>

            <Typography paragraph>
                В соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации совершение действий по
                выполнению указанных условий договора (в частности, оплата услуг) считается акцептом (согласием) оферты.
                При этом договор считается заключенным без подписания в каждом конкретном случае, так как акцепт оферты
                приравнивается к заключению договора на указанных ниже условиях.
            </Typography>

            <Divider sx={{ marginY: 2 }} />

            <Typography variant="h5" gutterBottom>
                1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
            </Typography>
            <Typography paragraph>
                <strong>Исполнитель</strong> — Индивидуальный предприниматель Котельников Юрий Тимофеевич.
            </Typography>
            <Typography paragraph>
                <strong>Заказчик</strong> — дееспособное физическое или юридическое лицо, оформившее Подписку на Сайте.
            </Typography>
            <Typography paragraph>
                <strong>Сайт</strong> — веб-сайт{' '}
                <a href="https://chatboost.space" target="_blank" rel="noopener noreferrer">
                    https://chatboost.space
                </a>
                , принадлежащий и администрируемый Исполнителем.
            </Typography>
            <Typography paragraph>
                <strong>Подписка</strong> — предоставление Заказчику доступа к приложению ChatBoost в Битрикс24 Маркете на
                платной основе в течение оплаченного периода.
            </Typography>
            <Typography paragraph>
                <strong>Приложение ChatBoost</strong> — программный продукт, доступный в Битрикс24 Маркете по ссылке:{' '}
                <a href="https://www.bitrix24.ru/apps/app/ekbapp_2.chatboost/" target="_blank" rel="noopener noreferrer">
                    https://www.bitrix24.ru/apps/app/ekbapp_2.chatboost/
                </a>
                .
            </Typography>
            <Typography paragraph>
                <strong>Договор</strong> — договор возмездного оказания услуг по Подписке, заключаемый между Исполнителем и
                Заказчиком на условиях настоящей Оферты.
            </Typography>


            <Typography variant="h5" gutterBottom>
                2. ОПИСАНИЕ СЕРВИСА И СПОСОБ ЕГО АКТИВАЦИИ
            </Typography>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    2.1.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Сервис «Рекуррентный платеж» позволяет Заказчику оплачивать услуги Исполнителя путем регулярных
                    автоматических переводов денежных средств в пользу Исполнителя с Банковских карт.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    2.2.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Все расчеты по Банковской карте производятся в рублях Российской Федерации.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    2.3.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Дополнительная плата за сервис «Рекуррентный платеж» не взимается.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    2.4.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Активация (подключение) сервиса «Рекуррентный платеж» предполагает выполнение следующих действий: Держатель
                    Банковской карты вводит на веб-странице ввода платежных данных данные своей Банковской карты и соглашается
                    на подключение к сервису «Рекуррентный платеж» путем установки отметки («галочки») в форме ввода данных в
                    личном кабинете на Сайте Исполнителя. После успешного прохождения операции сервис «Рекуррентный платеж»
                    будет активирован (подключен).
                </Typography>
            </Box>

            <Divider sx={{ marginY: 2 }} />

            <Typography variant="h5" gutterBottom>
                3. ДЕЙСТВИЕ СЕРВИСА «РЕКУРРЕНТНЫЙ ПЛАТЕЖ»
            </Typography>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    3.1.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    После подключения сервиса «Рекуррентный платеж» с Банковской карты будут осуществляться переводы денежных
                    средств в размерах, необходимых для надлежащего исполнения обязанностей по Договору.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    3.2.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Непосредственное списание денежных средств осуществляет Банк-эмитент на основании заранее данного
                    согласия Заказчика на подобное списание в суммах, указанных в Договоре. Запрос на перевод денежных
                    средств с указанием суммы перевода в Банк-эмитент передается Банком и Процессинговым центром в рамках
                    заключенных с Исполнителем соглашений.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    3.3.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Заказчик может активировать (подключить) сервис «Рекуррентный платеж» только по одной Банковской карте.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    3.4.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Исполнитель не хранит и не обрабатывает данные Банковской карты Заказчика, а также иные персональные данные
                    Заказчика, обеспечивая лишь запросы в Процессинговый центр и Банк для повторного проведения операции по
                    банковской карте Заказчика.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    3.5.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Исполнитель не гарантирует возможность проведения операций по Банковской карте.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    3.6.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Заказчик гарантирует, что он является Держателем банковской карты, осознанно, корректно и полностью вводит
                    все требуемые реквизиты Банковской карты при активации (подключении) сервиса «Рекуррентный платеж».
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    3.7.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Подключение сервиса «Рекуррентный платеж», в порядке, предусмотренном настоящим Соглашением, осуществляется
                    только при наличии технической возможности Исполнителя, Процессингового центра, Банка, Банка-эмитента.
                    Исполнитель, Процессинговый центр, Банк не несут ответственности за невозможность подключения сервиса
                    «Рекуррентный платеж».
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    3.8.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Уведомление Заказчика о любых изменениях производится Исполнителем посредством размещения указанной
                    информации на официальном Сайте Исполнителя.
                </Typography>
            </Box>

            <Divider sx={{ marginY: 2 }} />

            <Typography variant="h5" gutterBottom>
                4. ОТКЛЮЧЕНИЕ СЕРВИСА «РЕКУРРЕНТНЫЙ ПЛАТЕЖ»
            </Typography>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    4.1.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Отключение (отказ) Заказчика от сервиса «Рекуррентный платеж» происходит путем перехода на страницу{' '}
                    <Link href="https://chatboost.space/cancel-subscription" target="_blank" rel="noopener noreferrer">
                        https://chatboost.space/cancel-subscription
                    </Link>{' '}
                    и указания своего домена Битрикс24 и контактной электронной почты. После этого Заказчик может использовать
                    Подписку до конца оплаченного периода, и после его завершения платежи списываться не будут.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    4.2.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    В случае недостаточности денежных средств на счете Банковской карты для осуществления перевода Исполнитель
                    имеет право повторять запрос на списание с Банковской карты денежных средств.
                </Typography>
            </Box>

            <Divider sx={{ marginY: 2 }} />

            <Typography variant="h5" gutterBottom>
                5. ОТВЕТСТВЕННОСТЬ СТОРОН
            </Typography>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    5.1.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Исполнитель, Процессинговый центр, Банк-эмитент не несут ответственности за неработоспособность и/или
                    временную неработоспособность сервиса «Рекуррентный платеж». Заказчик самостоятельно контролирует
                    исполнение своей обязанности по оплате по Договору. При неработоспособности и/или временной
                    неработоспособности сервиса «Рекуррентный платеж» Заказчик обязан использовать иные способы исполнения
                    обязанностей по Договору.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    5.2.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Ответственность Исполнителя перед Заказчиком по настоящей Оферте ограничивается суммой денежных средств,
                    зачисленных с использованием сервиса «Рекуррентный платеж» на счет Исполнителя для оплаты услуг по
                    Договору.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    5.3.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Споры сторон, возникшие в связи с выполнением условий настоящей Оферты, разрешаются в соответствии с
                    условиями Договора.
                </Typography>
            </Box>

            <Divider sx={{ marginY: 2 }} />

            <Typography variant="h5" gutterBottom>
                6. ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ
            </Typography>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    6.1.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Возврат денежных средств производится в случае, если денежные средства списаны после отключения сервиса
                    «Рекуррентный платеж» через страницу{' '}
                    <Link href="https://chatboost.space/cancel-subscription" target="_blank" rel="noopener noreferrer">
                        https://chatboost.space/cancel-subscription
                    </Link>.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    6.2.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Возврат денежных средств производится в течение 10 банковских дней после получения от Заказчика заявления
                    о возврате денежных средств.
                </Typography>
            </Box>

            <Divider sx={{ marginY: 2 }} />

            <Typography variant="h5" gutterBottom>
                7. ИНЫЕ УСЛОВИЯ
            </Typography>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    7.1.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Выполняя действия по подключению сервиса «Рекуррентный платеж», Заказчик:
                </Typography>
                <Box sx={{ pl: 4, mt: 1 }}>
                    <Typography variant="body1" component="div">
                        7.1.1. Безусловно соглашается со всеми условиями настоящей Оферты.
                    </Typography>
                    <Typography variant="body1" component="div">
                        7.1.2. Дает заранее данный акцепт на списание денежных средств Исполнителем с Банковской карты на
                        условиях настоящей Оферты, а также признает все действия по безакцептному (автоматическому) списанию
                        денежных средств как выполненные с его согласия.
                    </Typography>
                    <Typography variant="body1" component="div">
                        7.1.3. Предоставляет право Процессинговому центру, Банку хранить, собирать, обрабатывать свои
                        персональные данные (Фамилию, Имя, Отчество, серию и номер паспорта, место и дату рождения, номер
                        расчетного счета и Банковской карты) с целью реализации настоящего Соглашения.
                    </Typography>
                    <Typography variant="body1" component="div">
                        7.1.4. Дает согласие на оплату услуг в соответствии с условиями Договора и Оферты.
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    7.2.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Исполнитель имеет право отключить и/или ограничить Заказчика от сервиса «Рекуррентный платеж».
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                    7.3.{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    Права и обязанности, вытекающие из настоящей Оферты, не могут быть переданы третьим лицам без письменного
                    согласия другой стороны.
                </Typography>
            </Box>

            <Divider sx={{ marginY: 2 }} />

            <Typography variant="h5" gutterBottom>
                8. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ
            </Typography>
            <Typography paragraph>
                Индивидуальный предприниматель: Котельников Юрий Тимофеевич <br />
                ИНН: 667354456790 <br />
                ОГРНИП: 319665800165693 <br />
                Расчетный счет: 40802810700001202317 <br />
                Банк: АО «ТБанк» <br />
                ИНН банка: 7710140679 <br />
                БИК банка: 044525974 <br />
                Корреспондентский счет банка: 30101810145250000974 <br />
                Юридический адрес банка: 127287, г. Москва, ул. 2-я Хуторская, д. 38А, стр. 26
            </Typography>
            <Typography paragraph>
                Справки можно получить по электронной почте:{' '}
                <a href="mailto:info@chatboost.space">info@chatboost.space</a>
            </Typography>
        </Container>
    );
};

export default PublicRecurrentOfferPage;
