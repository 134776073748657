import React, {FC, useEffect, useState} from 'react';
import {List, ListItem, ListItemText, Typography} from "@mui/material";
import ReuseComponentBuilder from "../ReuseComponent";
import {TeamResponse} from "../../types/api/TeamResponse";
import {useTeamsStorage} from "../../storage/TeamStore";
import Stack from "@mui/material/Stack";
import EditTeam from "./buttons/EditTeam";
import {useTranslation} from "react-i18next";

interface Props {
    portalId?: string;
}

const TeamsComponent: FC<Props> = ({portalId}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null | unknown>(null);
    const {teams} = useTeamsStorage()
    const { t } = useTranslation();

    useEffect(() => {
        initData().then()
    }, []);

    async function initData() {

    }

    return (
        <div>
            <ReuseComponentBuilder<TeamResponse[]>
                loading={loading}
                error={error}
                data={teams}
            >
                {(data) => (
                    <>
                        {
                            data.length > 0 && <List>
                                {
                                    data.map((item, index) => (
                                        <ListItem
                                            secondaryAction={
                                                <Stack direction={'row'}>
                                                    <EditTeam defaultTeam={item.defaultTeam}
                                                              name={item.name}
                                                              teamId={item.id}
                                                    />
                                                </Stack>
                                            }
                                            key={`team-${item.id}-${index}`}>
                                            <ListItemText
                                                primary={`${item.name}`}
                                                secondary={`${item.defaultTeam ? t('default_team') : ''}`}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        }
                        {
                            data.length == 0 && <Typography>{t('team_not_found')}</Typography>
                        }
                    </>
                )}
            </ReuseComponentBuilder>
        </div>
    );
};

export default TeamsComponent;