import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import MainPage from "./pages/MainPage/MainPage";
import ChatPage from "./pages/ChatPage/ChatPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import AddListPage from "./pages/AddListPage";
import NeedPayPage from "./pages/NeedPay/NeedPayPage";
import LandingPage from "./pages/Landing/landingPage";
import SendPage from "./pages/Send/SendPage";
import SuggestionsListPage from "./pages/MainPage/SuggestionsListPage";
import AppPage from "./pages/MainPage/AppPage";
import TeamsPage from "./pages/teams/TeamsPage";
import OrderPage from "./pages/MainPage/OrderPage";
import SettingsPage from "./pages/MainPage/SettingsPage";
import LoaderPage from "./pages/LoaderPage/LoaderPage";
import PrivacyPage from "./pages/Landing/PrivacyPage";
import UsersPage from "./pages/teams/UsersPage";
import OrderSussesResult from "./pages/MainPage/OrderSussesResult";
import LicenseAgreement from "./pages/MainPage/LicenseAgreementPage";
import B24PrivacyPolicy from "./pages/MainPage/B24PrivacyPolicyPage";
import PaymentResultPage from "./pages/MainPage/PaymentResultPage";
import AnalyticsPage from "./pages/MainPage/AnalyticsPage";
import PublicOfferPage from "./pages/MainPage/PublicOfferPage";
import UnsubscribeForm from "./pages/MainPage/CancelSubscriptionPage";
import PublicRecurrentOfferPage from "./pages/Landing/PublicRecyrrentOfferPage";
import SupportPage from "./pages/MainPage/SupportPage";
import FilesListPage from "./pages/FilesPage/FilesListPage";
import NewSuggestionPage from "./pages/MainPage/NewSuggestionPage";
import './i18n';

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <LandingPage/>
        ),
    },
    {
        path: "/chat/:portalId",
        element: <ChatPage/>,
    },
    {
        path: "/send/:id",
        element: <SendPage/>,
    },
    {
        path: '/main',
        element: <MainPage/>
    },
    {
        path: '/payment/susses',
        element: <PaymentResultPage result={'susses'}/>
    },
    {
        path: '/payment/fail',
        element: <PaymentResultPage result={'fail'}/>
    },
    {
        path: '/app/:portalId',
        element: <LoaderPage/>,
    },
    {
        path: '/list/:listId',
        element: <SuggestionsListPage/>,
    },
    {
        path: '/files',
        element: <FilesListPage/>,
    },
    {
        path: '/list/:listId/new-suggestion',
        element: <NewSuggestionPage/>,
    },
    {
        path: '/main-app',
        element: <AppPage/>,
    },
    {
        path: '/team',
        element: <TeamsPage/>
    },
    {
        path: '/license-agreement',
        element: <LicenseAgreement/>
    },
    {
        path: '/b24-privacy-policy',
        element: <B24PrivacyPolicy/>
    },
    {
        path: '/orders',
        element: <OrderPage/>,
    },
    {
        path: '/settings',
        element: <SettingsPage/>,
    },
    {
        path: '/users',
        element: <UsersPage/>,
    },
    {
        path: '/susses/:portalId',
        element: <OrderSussesResult/>,
    },
    {
        path: '/add-list',
        element: <AddListPage/>
    },
    {
        path: '/error',
        element: <ErrorPage/>
    },
    {
        path: '/need_pay',
        element: <NeedPayPage/>
    },
    {
        path: '/privacy',
        element: <PrivacyPage/>
    },
    {
        path: '/analytics',
        element: <AnalyticsPage/>
    },
    {
        path: '/offer',
        element: <PublicOfferPage/>
    },
    {
        path: '/recurrent-offer',
        element: <PublicRecurrentOfferPage/>
    },
    {
        path: '/cancel-subscription',
        element: <UnsubscribeForm/>
    }, {
        path: '/support',
        element: <SupportPage/>
    },
    {
        path: '*',
        element: <ErrorPage message={'Похоже страница не найдена'}/>,
    }
]);

function App() {


    return (
        <RouterProvider router={router}/>
    )
}

export default App;
